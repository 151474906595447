<template>
  <div class="result-container">
    <my-header :isSenior.sync="isSenior"></my-header>
    <div class="content">
      <div class="title"></div>
      <div class="info">
        <div>
          <div class="left">
            <div class="box1">
              <p class="baseData_title">{{ baseData.title }}</p>
              <div class="box1_item" v-show="baseData.title">
                <p>正题名：</p>
                <span> {{ baseData.title }} </span>
              </div>
              <div class="box1_item" v-show="baseData.parallelTitle">
                <p>并列题名：</p>
                <span> {{ baseData.parallelTitle }} </span>
              </div>
              <div class="box1_item" v-show="baseData.alternative">
                <p>其他题名：</p>
                <span> {{ baseData.alternative }} </span>
              </div>
              <div class="box1_item" v-show="baseData.translateTitle">
                <p>翻译题名：</p>
                <span> {{ baseData.translateTitle }} </span>
              </div>
              <div class="box1_item" v-show="baseData.seriesArea">
                <p>丛编题名：</p>
                <span> {{ baseData.seriesArea }} </span>
              </div>
              <div class="box1_item" v-show="baseData.numberOfVolumes">
                <p>卷册数：</p>
                <span> {{ baseData.numberOfVolumes }} </span>
              </div>
              <div class="box1_item" v-show="baseData.contributor">
                <p>责任者：</p>
                <span> {{ baseData.contributor }} </span>
              </div>
              <div class="box1_item" v-show="baseData.createOfOrganization">
                <p>机构：</p>
                <span> {{ baseData.createOfOrganization }} </span>
              </div>
              <div class="box1_item" v-show="baseData.otherContributor">
                <p>其他责任者：</p>
                <span> {{ baseData.otherContributor }} </span>
              </div>
              <div class="box1_item" v-show="baseData.otherRole">
                <p>责任方式：</p>
                <span> {{ baseData.otherRole }} </span>
              </div>
              <div class="box1_item" v-show="baseData.classification">
                <p>分类号：</p>
                <span> {{ baseData.classification }} </span>
              </div>
              <div class="box1_item" v-show="baseData.keyword">
                <p>关键词：</p>
                <span> {{ baseData.keyword }} </span>
              </div>
              <div class="box1_item" v-show="baseData.dateOfEditor">
                <p>纂修日期：</p>
                <span> {{ baseData.dateOfEditor }} </span>
              </div>
              <div class="box1_item" v-show="baseData.dateOfPublication">
                <p>出版日期：</p>
                <span> {{ baseData.dateOfPublication }} </span>
              </div>
              <div class="box1_item" v-show="baseData.dateOfUpdate">
                <p>重印、增补等日期：</p>
                <span> {{ baseData.dateOfUpdate }} </span>
              </div>
              <div class="box1_item" v-show="baseData.language">
                <p>语种：</p>
                <span> {{ baseData.language }} </span>
              </div>
              <div class="box1_item" v-show="baseData.TWClassification">
                <p>台湾分类：</p>
                <span> {{ baseData.TWClassification }} </span>
              </div>
              <div
                class="box1_item"
                v-show="baseData.placeOfPublication || baseData.publisher"
              >
                <p>出版信息：</p>
                <span v-show="baseData.placeOfPublication">
                  {{ baseData.placeOfPublication }},
                </span>
                <span v-show="baseData.publisher">{{
                  baseData.publisher
                }}</span>
              </div>
            </div>
            <div class="box2">
              <div class="box2_left">
                <p class="baseData_title">载体信息</p>
                <div class="box1_item" v-show="baseData.bookBinding">
                  <p>装订方式：</p>
                  <span> {{ baseData.bookBinding }} </span>
                </div>
                <div class="box1_item" v-show="baseData.form">
                  <p>形态形制：</p>
                  <span> {{ baseData.form }} </span>
                </div>
                <div class="box1_item" v-show="baseData.bookSize">
                  <p>开本：</p>
                  <span> {{ baseData.bookSize }} </span>
                </div>
                <div class="box1_item" v-show="baseData.printNumber">
                  <p>印数：</p>
                  <span> {{ baseData.printNumber }} </span>
                </div>
                <div class="box1_item" v-show="baseData.wordNumber">
                  <p>字数：</p>
                  <span> {{ baseData.wordNumber }} </span>
                </div>
                <div class="box1_item" v-show="baseData.extent">
                  <p>页数：</p>
                  <span> {{ baseData.extent }} </span>
                </div>
                <div class="box1_item" v-show="baseData.price">
                  <p>定价：</p>
                  <span> {{ baseData.price }} </span>
                </div>
                <div class="box1_item" v-show="baseData.unionBooksNumber">
                  <p>唯一标识符：</p>
                  <span> {{ baseData.unionBooksNumber }} </span>
                </div>
                <div class="box1_item" v-show="baseData.edition">
                  <p>版本类别：</p>
                  <span> {{ baseData.edition }} </span>
                </div>
                <div class="box1_item" v-show="baseData.revision">
                  <p>版次：</p>
                  <span> {{ baseData.revision }} </span>
                </div>
                <div class="box1_item" v-show="baseData.size">
                  <p>尺寸：</p>
                  <span> {{ baseData.size }} </span>
                </div>
              </div>
              <div class="box2_right">
                <p class="baseData_title">数字化信息</p>
                <div class="box1_item" v-show="baseData.resourceStyle">
                  <p>资源体例：</p>
                  <span> {{ baseData.resourceStyle }} </span>
                </div>
                <div class="box1_item" v-show="baseData.resourceType">
                  <p>资源类型：</p>
                  <span> {{ baseData.resourceType }} </span>
                </div>
                <div class="box1_item" v-show="baseData.temporal">
                  <p>时空范围：</p>
                  <span> {{ baseData.temporal }} </span>
                </div>
                <div class="box1_item" v-show="baseData.spatial">
                  <p>空间范围：</p>
                  <span> {{ baseData.spatial }} </span>
                </div>
                <div class="box1_item" v-show="baseData.rightsHolder">
                  <p>权限持有者：</p>
                  <span> {{ baseData.rightsHolder }} </span>
                </div>
                <div class="box1_item" v-show="baseData.ownership">
                  <p>权限归属：</p>
                  <span> {{ baseData.ownership }} </span>
                </div>
                <div class="box1_item" v-show="baseData.relation">
                  <p>关联名称：</p>
                  <span> {{ baseData.relation }} </span>
                </div>
                <div class="box1_item" v-show="baseData.relationType">
                  <p>关联类型：</p>
                  <span> {{ baseData.relationType }} </span>
                </div>
                <div class="box1_item" v-show="baseData.relationObject">
                  <p>关联对象：</p>
                  <span> {{ baseData.relationObject }} </span>
                </div>

                <div class="box1_item" v-show="baseData.sourceType">
                  <p>来源方式：</p>
                  <span> {{ baseData.sourceType }} </span>
                </div>
                <div class="box1_item" v-show="baseData.sourceFrom">
                  <p>提供方名称：</p>
                  <span> {{ baseData.sourceFrom }} </span>
                </div>

                <div class="box1_item" v-show="baseData.repositoryNumber">
                  <p>典藏号：</p>
                  <span> {{ baseData.repositoryNumber }} </span>
                </div>
                <div class="box1_item" v-show="baseData.shefCode">
                  <p>排架区分号：</p>
                  <span> {{ baseData.shefCode }} </span>
                </div>
                <div class="box1_item" v-show="baseData.collectDate">
                  <p>入藏日期：</p>
                  <span> {{ baseData.collectDate }} </span>
                </div>
                <div class="box1_item" v-show="baseData.saveState">
                  <p>保存状态：</p>
                  <span> {{ baseData.saveState }} </span>
                </div>
                <div class="box1_item" v-show="baseData.integrityLevel">
                  <p>完残程度：</p>
                  <span> {{ baseData.integrityLevel }} </span>
                </div>
                <div class="box1_item" v-show="baseData.storer">
                  <p>收藏人：</p>
                  <span> {{ baseData.storer }} </span>
                </div>
                <div class="box1_item" v-show="baseData.storeStory">
                  <p>收藏历史：</p>
                  <span> {{ baseData.storeStory }} </span>
                </div>
                <div class="box1_item" v-show="baseData.researcher">
                  <p>整理研究人：</p>
                  <span> {{ baseData.researcher }} </span>
                </div>
                <div
                  class="box1_item digitalize"
                  v-show="baseData.researchStory"
                >
                  <p>整理研究历史：</p>
                  <span> {{ baseData.researchStory }} </span>
                </div>
                <div class="box1_item digitalize" v-show="baseData.localID">
                  <p>提供方本地资源标识：</p>
                  <span> {{ baseData.localID }} </span>
                </div>
                <div
                  class="box1_item digitalize"
                  v-show="baseData.collectorCode"
                >
                  <p>收藏单位代码：</p>
                  <span> {{ baseData.collectorCode }} </span>
                </div>
                <div
                  class="box1_item digitalize"
                  v-show="baseData.collectorName"
                >
                  <p>收藏单位名称：</p>
                  <span> {{ baseData.collectorName }} </span>
                </div>
                <div
                  class="box1_item digitalize"
                  v-show="baseData.digitialSource"
                >
                  <p>数字加工来源格式：</p>
                  <span> {{ baseData.digitialSource }} </span>
                </div>
                <div class="box1_item digitalize" v-show="baseData.idadDate">
                  <p>数字化加工交付时间：</p>
                  <span> {{ baseData.idadDate }} </span>
                </div>
                <div
                  class="box1_item digitalize"
                  v-show="baseData.standardOfExecution"
                >
                  <p>数字化加工执行标准：</p>
                  <span> {{ baseData.standardOfExecution }} </span>
                </div>
              </div>
            </div>
            <div class="box3">
              <p class="baseData_title">内容简介</p>
              <div class="box1_item" v-show="baseData.lifeOfEditor">
                <p>编纂者生平：</p>
                <span v-html="baseData.lifeOfEditor"></span>
              </div>
              <div class="box1_item" v-show="baseData.abstract">
                <p>摘要：</p>
                <span v-html="baseData.abstract"> </span>
              </div>
              <div class="box1_item" v-show="baseData.notes">
                <p>一般性附注：</p>
                <span> {{ baseData.notes }} </span>
              </div>
              <div class="box1_item" v-show="baseData.titleOfNotes">
                <p>题名附注：</p>
                <span> {{ baseData.titleOfNotes }} </span>
              </div>
              <div class="box1_item" v-show="baseData.carrierOfNotes">
                <p>载体附注：</p>
                <span> {{ baseData.carrierOfNotes }} </span>
              </div>
              <div class="box1_item" v-show="baseData.aboutOfNotes">
                <p>相关附注：</p>
                <span> {{ baseData.aboutOfNotes }} </span>
              </div>
              <div class="box1_item" v-show="baseData.roleOfNotes">
                <p>责任附注：</p>
                <span> {{ baseData.roleOfNotes }} </span>
              </div>
              <div class="box1_item" v-show="baseData.contentOfNotes">
                <p>内容附注：</p>
                <span> {{ baseData.contentOfNotes }} </span>
              </div>
            </div>
            <div class="box4" v-if="EBookData.length">
              <p class="baseData_title">卷次篇目名称</p>
              <el-tree
                v-for="item in EBookData"
                :key="item.id"
                :data="item.treeData"
                :default-expanded-keys="treeExpandData"
                :props="defaultProps"
                node-key="id"
                @node-click="handleNodeClick(item, $event)"
              >
                <span
                  slot-scope="{ node }"
                  class="custom-tree-node showname"
                  v-text="node.label"
                >
                </span>
              </el-tree>
            </div>
          </div>
        </div>

        <div class="right">
          <template
            v-if="
              ($route.query.type && $route.query.type != 2) || coverList.length
            "
          >
            <div class="img" v-show="coverList.length">
              <el-carousel
                trigger="click"
                height="492px"
                :loop="true"
                :autoplay="true"
                @change="onchange"
              >
                <el-carousel-item v-for="v in coverList" :key="v.id">
                  <img
                    @click="goToEBook(v)"
                    style="max-width: 100%"
                    :src="`/api/fileinfo/ebook/GetEBookFaceForDynamic?path=${v.facePath}`"
                    alt=""
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div
              class="btn"
              v-show="coverList.length"
              @click="goToEBook(coverList[currentIndex])"
            >
              查看电子书
            </div>
          </template>
          <div class="btn" @click="goToCLick">查看原图</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { getBaseData, getCover } from "@/api/literature.js";
import myHeader from "@/components/header/index.vue";
import { Loading } from "element-ui";
export default {
  components: {
    myHeader,
  },
  data() {
    return {
      isSenior: false,
      currentIndex: 0,
      isShow: false,
      sourceID: "",
      keyword: "",
      baseData: {},
      coverList: [],
      picList: [],
      tempPicList: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      treeExpandData: [],
      EBookData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
    };
  },
  async created() {
    this.sourceID = this.$route.query.sourceID;
    this.keyword = this.$route.query.keyword;
    await this.getBaseData();
    this.getCover();
    // this.getPic();
  },
  methods: {
    goToCLick() {
      let routeData = this.$router.resolve({
        path: "/ebook",
        query: {
          sourceID: this.sourceID,
          searchTitle: this.$route.query.searchTitle,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 轮播图切换
    onchange(currentIndex, oldIndex) {
      this.currentIndex = currentIndex;
    },
    handleNodeClick(data, e) {
      let index = this.EBookData.findIndex((v) => v.id === data.id);
      // this.setBookInfo();
      let routeData = this.$router.resolve({
        path: "/ebook2",
        query: {
          sourceID: this.sourceID,
          index: e.pageIndex,
          bookIndex: index,
          bookId: data.id,
          checkedId: e.id,
          Keyword: this.$route.query.searchTitle,
        },
      });
      window.open(routeData.href, "_blank");
    },
    onCurrentChange(index) {
      this.pageIndex = index;
      this.tempPicList = this.picList.slice(
        (this.pageIndex - 1) * this.pageSize,
        this.pageIndex * this.pageSize
      );
    },
    setBookInfo() {
      const info = {
        contributor: this.baseData.contributor, // 责任者
        title: this.baseData.title, // 题名
        placeOfPublication: this.baseData.placeOfPublication, // 出版地
        publisher: this.baseData.publisher, // 出版者
        dateOfPublication: this.baseData.dateOfPublication, // 出版日期
        seriesArea: this.baseData.seriesArea,
      };
      this.$store.commit("setBookInfo", info);
    },
    goToEBook(data) {
      this.setBookInfo();
      let routeData = this.$router.resolve({
        path: "/ebook2",
        query: {
          sourceID: this.sourceID,
          bookId: data.id,
          Keyword: this.$route.query.searchTitle,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 基础数据
    async getBaseData() {
      const res = await getBaseData({ sourceID: this.sourceID });
      this.baseData = res;
      const pattern = /\[[^\]]*\d[^\]]*\]/g;
      if (this.baseData.abstract) {
        this.baseData.abstract = this.baseData.abstract.replace(
          pattern,
          "<img title='$&' alt='$&' src='http://112.111.62.151:9000/api/proofread/WordBuilder/GetWordImageByCode?code=$&' style='width: 23px; height: 23px'/><span style='display: none'>$&</span>"
        );
      }
      if (this.baseData.lifeOfEditor) {
        this.baseData.lifeOfEditor = this.baseData.lifeOfEditor.replace(
          pattern,
          "<img title='$&' alt='$&' src='http://112.111.62.151:9000/api/proofread/WordBuilder/GetWordImageByCode?code=$&' style='width: 23px; height: 23px'/><span style='display: none'>$&</span>"
        );
      }
    },
    onClick(e) {
      if (e.target.nodeName === "IMG") {
        let index = this.EBookData.findIndex(
          (v) => v.id === JSON.parse(e.target.dataset.item).ebookID
        );
        if (index === -1) return;
        this.setBookInfo();
        this.$store.commit("setLocations", JSON.parse(e.target.dataset.item));
        let routeData = this.$router.resolve({
          name: "EBook",
          query: {
            sourceID: this.sourceID,
            pageIndex: e.target.dataset.index,
            keyword: this.$route.query?.keyword,
            bookIndex: index,
            checkedId: e.target.dataset.id,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    NextFun() {
      if (this.leftVal == (this.coverList.length - 1) * this.imgWidth) {
        // 判断显示的图片 是 最后一张时执行
        this.ition = 0.8;
        this.leftVal += this.imgWidth;
        this.currentIndex = 0;
        setTimeout(() => {
          this.ition = 0;
          this.leftVal = 0;
        }, this.ition * 1000);
      } else {
        // 判断显示的图片 不是 最后一张时执行
        this.ition = 0.8;
        this.leftVal += this.imgWidth;
        this.currentIndex++;
      }
    },
    // 封面
    async getCover() {
      let loading = Loading.service({
        fullscreen: true,
        lock: true,
        text: "Loading",
        target: ".img",
        spinner: "el-icon-loading",
        // background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        const res = await getCover({
          SourceID: this.baseData.sourceID,
          isNoHasPages: true,
          PageIndex: 1,
          PageSize: 50,
        });
        this.coverList = res.items;
        this.EBookData = res.items;
        this.EBookData.forEach((v) => {
          let arr = _.groupBy(v.eBookNav, "parentNav");
          for (var v1 of v.eBookNav) {
            v1.children = arr[v1.id];
          }
          v.treeData = arr[""];
        });
        this.treeExpandData.push(this.EBookData[0].treeData[0].id);
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    // 缩略图
    async getPic() {
      const loading = this.$loading({
        lock: true,
        target: ".list",
        text: "加载中...",
        spinner: "el-icon-loading",
      });
      try {
        const res = await handlerSearch({
          sourceID: this.sourceID,
          key: this.keyword,
        });
        this.picList = res;
        this.total = res.length;
        if (this.total >= 10) {
          this.tempPicList = this.picList.slice(0, 10);
        } else {
          this.tempPicList = res;
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tree {
  color: #836f64;

  // 超出的文字显示省略号
  .el-tree-node__label {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .el-tree-node:focus > .el-tree-node__content {
    color: #02b3be;
    background: #fff;
  }
  .el-tree-node.is-current > .el-tree-node__content {
    color: #02b3be;
  }
  .el-tree-node__content:hover,
  .el-upload-list__item:hover {
    color: #02b3be;
  }
  .el-tree-node:focus > .el-tree-node__content {
    color: #02b3be;
  }
  .el-icon-caret-right:before {
    color: #02b3be;
  }

  // 展开时三角图标颜色
  .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
    color: #02b3be;
  }
  // 未展开时三角图标颜色
  .el-icon-caret-right:before {
    color: #02b3be;
  }

  // 没有子节点时三角图标颜色（一般不显示）
  .el-tree-node__expand-icon.is-leaf::before {
    color: rgba(0, 0, 0, 0); // 透明
  }
}
.result-container {
  background: #f7f7f7;
  padding-bottom: 100px;
  .content {
    margin: 30px auto 0;
    background: #fff;
    padding-bottom: 20px;
    width: 1326px;

    .title {
      padding-left: 20px;
      font-size: 16px;
      color: #776258;
      font-weight: 700;
      line-height: 48px;
      background: #e0d0b6;
    }

    .info {
      display: flex;

      .btn {
        margin: 10px auto;
        width: 196px;
        height: 48px;
        background: #e6ded4;
        font-size: 16px;
        color: #a24243;
        line-height: 48px;
        text-align: center;
        cursor: pointer;
        // margin-left: 44px;
      }
      .left {
        box-sizing: border-box;
        width: 1004px;
        padding: 10px 0px 0 36px;
        // display: flex;
        // flex-wrap: wrap;
        // align-content: flex-start;
        .box1,
        .box2,
        .box3,
        .box4 {
          padding: 20px 50px 20px 100px;
          border-bottom: 1px dashed #a24243;
          .baseData_title {
            display: inline-block;
            font-size: 18px;
            font-weight: 700;
            color: #836f64;
            border-bottom: 2px solid #836f64;
            padding-bottom: 5px;
            padding-right: 40px;
          }

          .box1_item {
            margin: 15px 0 0 20px;
            font-size: 16px;
            display: flex;
            align-items: center;

            p {
              margin-right: 10px;
              display: inline-block;
              width: 100px;
              text-align: justify;
              text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
              text-align-last: justify;
              word-break: break-all;
              text-justify: distribute;
            }
          }
        }
        .box2 {
          display: flex;
          justify-content: space-between;

          .digitalize {
            p {
              display: inline-block;
              min-width: 150px;
              white-space: nowrap;
              text-justify: none;
              word-break: normal;
            }
          }
        }
        .box3 {
          padding: 20px 0px 20px 100px;
          .box1_item {
            margin: 15px 0 0 20px;
            font-size: 16px;
            display: flex;
            align-items: flex-start;
            p {
              margin-right: 10px;
              display: inline-block;
              min-width: 100px;
            }
            span {
              line-height: 20px;
            }
          }
        }
        .box4 {
          border: none;
        }
        div:last-child {
          border: none;
        }
      }

      .right {
        padding: 30px 36px 0;
        text-align: center;
        ::v-deep .img {
          cursor: pointer;
          width: 262px;
          height: 492px;
          .el-carousel__indicators {
            display: none;
          }
        }
      }
    }
  }
  .result {
    margin: 20px auto 0;
    width: 1326px;
    padding: 0 30px;
    background: #fff;

    ::v-deep .paging {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      li {
        width: 30px;
        height: 30px;
        background: #ececef;
        color: #2b2b2b;
      }
      li:not(.disabled).active {
        background: #3e74ae;
      }
    }

    .title {
      height: 56px;
      font-size: 18px;
      padding: 20px;
      color: #836f64;
      width: 1240px;
      border-bottom: 1px solid #d4c8b9;
    }

    .list {
      padding: 0px 0 20px 30px;
      display: flex;
      flex-wrap: wrap;

      .item {
        margin-top: 36px;
        width: 200px;
        height: 244px;
        border: 1px solid #e6ded4;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 44px;
        cursor: pointer;

        .img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
</style>
